@font-face {
  font-family: "Bold";
  src: url("./assets/fonts/Apex_New_Bold.woff") format("woff");
}

@font-face {
  font-family: "Medium";
  src: url("./assets/fonts/Apex_New_Medium.woff") format("woff");
}

@font-face {
  font-family: "Book";
  src: url("./assets/fonts/Apex_New_Book.woff") format("woff");
}

@font-face {
  font-family: "Unipol-Bold";
  src: url("./assets/fonts/Apex_New_Bold.woff") format("woff");
}

@font-face {
  font-family: "Unipol-Medium";
  src: url("./assets/fonts/Apex_New_Medium.woff") format("woff");
}

@font-face {
  font-family: "Unipol-Book";
  src: url("./assets/fonts/Apex_New_Book.woff") format("woff");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Book";
  &.captcha-event {
    pointer-events: none;
    iframe {
      pointer-events: auto;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page-container-padding-x {
  padding-left: 10px;
}

.foto-contesto-file-upload {
  .foto-contesto-file-upload.wrapped {
    margin-top: 0;
    div {
      height: 125px;
      width: 100px;
      padding: 0;
    }
    input {
      height: 125px;
    }
  }
}
